import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@core/services/language.service';
import { OnInit, OnDestroy, Input } from '@angular/core';

export interface ILanguage {
  code: string;
  name: string;
}

export const languageList: ILanguage[] = [
  {code: 'ru', name: 'Русский'},
  {code: 'mk', name: 'Македонски'},
];

export abstract class LanguageSwitcherBase implements OnInit, OnDestroy {
  currentLanguage: ILanguage;
  url: string;
  readonly sub$: Subscription = new Subscription();

  @Input() set supportedLanguages(langs: string[]) {
    if (!langs) {
      langs = ['ru', 'mk'];
    }
    this.languages = languageList.filter( lang => langs.indexOf(lang.code) > -1);
  }

  languages: ILanguage[] = [
    {code: 'ru', name: 'Русский'},
    {code: 'mk', name: 'Македонски'},
  ];

  protected constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected languageService: LanguageService,
  ) {
  }

  ngOnInit() {
    this.getUrl();
    this.getCurrentLanguage();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  getUrl() {
    this.sub$.add(
      this.route.queryParams.subscribe(() => {
        this.url = this.router.url;
      })
    );
  }

  getCurrentLanguage() {
    this.currentLanguage = this.languageService.getCurrentLanguage(this.languages);
  }
}
