import { Injectable } from '@angular/core';
import { CartItem, CartIncrementQuery, CartChangeItemQuery, CartDeleteItem, CartUpdateCityQuery, CartReward,
   CartCondition,
   UserModel,
   ProductStatuses} from '@core/models';
import { ReplaySubject, Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { CartService } from '@core/services/cart/cart.service';
import { tap, catchError, mergeMap, filter } from 'rxjs/operators';
import { CityService } from '@core/services/hdbk/city.service';
import { CurrencyService } from '@core/services/currency.service';
import { UserHelperService } from '@core/services/user-helper.service';
import { StockService } from '@core/services/user/stock.service';

@Injectable({
  providedIn: 'root'
})
export class CartViewService {
  cartItems$ = new ReplaySubject<CartItem[]>(1);
  cartRewars$ = new BehaviorSubject<CartReward[]>(null);
  cartItemAmountChanged$ = new BehaviorSubject<number>(null);
  cartItemsUpdate$ = new BehaviorSubject<any>(null);
  cartItems: CartItem[] = [];
  selectedCartItems: CartItem[] = [];
  discount: number = 0;
  totalPrice: number = 0;
  totalCustomerPrice: number = 0;
  selectedTotalAmount: number = 0;
  totalAmount: number = 0;
  deliveryPrice: number = 0;
  markupPercent: number = 0; // Процент региональной наценки
  markupPrice: number = 0;
  markupCustomerPrice: number = 0;
  totalPriceWithDelivery: number = 0;
  totalCustomerPriceWithDelivery: number = 0;
  points: number =  0;
  runCartUpdate$ = new BehaviorSubject(null);
  stockConditionsUpdate$ = new BehaviorSubject<CartCondition[]>(null);
  user: UserModel;
  sub: Subscription = new Subscription();

  get cartRewards(): CartReward[] {
    const cartRewards: CartReward[] = [];
    for (const cartItem of this.selectedCartItems) {
      if (cartItem.reward_products && cartItem.reward_products.length > 0) {
        for (const cartReward of cartItem.reward_products) {
          if (cartReward.amount > 0 ) {
            cartRewards.push(cartReward);
          }
        }
      }
    }
    return cartRewards;
  }

  constructor(private cartService: CartService, private cityService: CityService, private currencyService: CurrencyService,
    private userService: UserHelperService, private stockService: StockService) {
    this.subscribeToCityChange();
    this.subscribeToUser();
   }

   subscribeToUser() {
     this.userService.user$.subscribe((user) => {
       if(user) {
        this.user = user;
        this.getStockConditions();
       }
      });
   }

  calculateTotal() {
    this.resetCalculatedValues();
    this.selectedCartItems.forEach( item => {
       this.totalPrice += item.amount * item.product.price_partner;
       this.totalCustomerPrice += item.amount * item.product.price_customer;
       this.selectedTotalAmount += item.amount;
       this.points += item.product.points_by_user * item.amount;
    });
    this.cartRewards.forEach( item => {
      this.selectedTotalAmount += item.amount;
    });
    this.markupPrice = this.totalPrice * this.markupPercent/100;
    this.markupCustomerPrice = this.totalCustomerPrice * this.markupPercent/100;
    if (this.deliveryPrice) {
      this.totalPriceWithDelivery = this.totalPrice + +this.deliveryPrice + this.markupPrice;
      this.totalCustomerPriceWithDelivery = this.totalCustomerPrice + +this.deliveryPrice + this.markupCustomerPrice;
    }
    this.discount = this.totalCustomerPrice - this.totalPrice;
    this.calculateCartTotalAmount();
  }

  selectCartItem(cartItem: CartItem): Observable<any> {
   return this.cartService.selectCartItem(cartItem.product.slug).pipe(tap(() => {
      cartItem.is_selected = true;
      this.selectedCartItems.push(cartItem);
      this.calculateTotal();
    }),
    mergeMap(() => this.cartService.getStockConditions()),
    tap((cartConditions: CartCondition[]) => {
      if(cartConditions) {
        this.stockConditionsUpdate$.next(cartConditions);
        this.checkStockConditions(cartConditions);
        this.getCartRewards();
      }
    })
    );
  }

  deselectCartItem(cartItem: CartItem): Observable<any> {
    return this.cartService.deselectCartItem(cartItem.product.slug).pipe(tap(() => {
     cartItem.is_selected = false;
     // tslint:disable-next-line: prefer-for-of
     for (let i = 0; i < this.selectedCartItems.length; i++) {
       if (this.selectedCartItems[i].product.id === cartItem.product.id) {
         this.selectedCartItems.splice(i, 1);
         break;
       }
     }
     this.calculateTotal();
     }),
     mergeMap(() => this.cartService.getStockConditions()),
     tap((cartConditions: CartCondition[]) => {
       if(cartConditions) {
         this.stockConditionsUpdate$.next(cartConditions);
         this.checkStockConditions(cartConditions);
         this.getCartRewards();
       }
     }));
   }

  selectAllCartItems(): Observable<any> {
    const availableCartItems: CartItem[] = this.cartItems.filter(( cartItem: CartItem) => this.isCartItemAvailable(cartItem));
    if(availableCartItems && availableCartItems.length > 0) {
      const slugs = availableCartItems.map((cartItem: CartItem) => cartItem.product.slug).join(',');
      return this.cartService.selectCartItem(slugs).pipe(tap( () => {
        availableCartItems.map(cartItem => cartItem.is_selected = true);
        this.selectedCartItems = availableCartItems;
        this.calculateTotal();
        this.getCartRewards();
      }),
      mergeMap(() => this.cartService.getStockConditions()),
      tap((cartConditions: CartCondition[]) => {
        if(cartConditions) {
          this.stockConditionsUpdate$.next(cartConditions);
          this.checkStockConditions(cartConditions);
        }
      }));
    } else {
      return of(null);
    }
  }

  deselectAllCartItems() {
    const availableCartItems: CartItem[] = this.cartItems.filter(( cartItem: CartItem) => this.isCartItemAvailable(cartItem));
    if(availableCartItems && availableCartItems.length > 0) {
      const slugs = availableCartItems.map((cartItem: CartItem) => cartItem.product.slug).join(',');
      return this.cartService.deselectCartItem(slugs).pipe(tap( () => {
        availableCartItems.map(cartItem => {
          cartItem.is_selected = false;
          cartItem.reward_products = null;
        } );
        this.selectedCartItems = [];
        this.calculateTotal();
      }),
      mergeMap(() => this.cartService.getStockConditions()),
      tap((cartConditions: CartCondition[]) => {
        if(cartConditions) {
          this.stockConditionsUpdate$.next(cartConditions);
        }
      }));
    } else {
      return of(null);
    }

  }

  increment(slug: string): Observable<any> {
    const query = new CartIncrementQuery(slug);
    return  this.cartService.incrementCartItem(query).pipe(
      tap(() => {
        const cartItem = this.findCartItem(slug);
        if (cartItem) {
          cartItem.amount++;
          this.calculateTotal();
          this.cartItemAmountChanged$.next(cartItem.reward_group_index);
        }
      }),
      mergeMap(() => this.user? this.cartService.getStockConditions() : of(null)),
      tap((cartConditions: CartCondition[]) => {
        if(cartConditions) {
          this.stockConditionsUpdate$.next(cartConditions);
          this.checkStockConditions(cartConditions);
        }
    }));
  }
  decrement(slug: string): Observable<any> {
    const query = new CartIncrementQuery(slug);
    return this.cartService.decrementCartItem(query).pipe(
      tap(() => {
        const cartItem = this.findCartItem(slug);
        if (cartItem) {
          cartItem.amount--;
          this.calculateTotal();
          this.cartItemAmountChanged$.next(cartItem.reward_group_index);
        }
      }),
      mergeMap(() => this.user? this.cartService.getStockConditions() : of(null)),
      tap ((cartConditions: CartCondition[]) => {
        if(cartConditions) {
          this.stockConditionsUpdate$.next(cartConditions);
          this.checkStockConditions(cartConditions);
        }
    }));
  }

  changeAmount(slug: string, amount: number) {
    // new
    const query = new CartChangeItemQuery(slug);
    query.amount = amount;
    return this.cartService.changeCartItem(query).pipe(
      tap(() => {
        const cartItem = this.findCartItem(slug);
        if (cartItem) {
          cartItem.amount = amount;
          this.calculateTotal();
          this.cartItemAmountChanged$.next(cartItem.reward_group_index);
        }
      }),
      mergeMap(() => this.user? this.cartService.getStockConditions() : of(null)),
      tap((cartConditions: CartCondition[]) => {
        if(cartConditions) {
          this.stockConditionsUpdate$.next(cartConditions);
          this.checkStockConditions(cartConditions);
        }
    }));
  }

  private findCartItem(slug: string): CartItem {
   return this.cartItems.find((cartItem: CartItem) => cartItem.product.slug === slug);
  }

  resetCalculatedValues() {
    this.discount = 0;
    this.totalPrice = 0;
    this.totalCustomerPrice = 0;
    this.selectedTotalAmount = 0;
    this.totalAmount = 0;
    this.points = 0;
  }

  calculateCartTotalAmount() {
    this.totalAmount = 0;
    this.cartItems.forEach( item => {
      this.totalAmount += item.amount;
   });
  }

  deleteCartItem(slug: string): Observable<any> {
    const query = new CartDeleteItem(slug);
    return this.cartService.deleteCartItem(query).pipe(
     tap(() => {
      const cartItem = this.findCartItem(slug);
      if (cartItem) {
        this.deselectCartItem(cartItem);
        this.cartItems.splice(this.cartItems.findIndex((item) => item.product.id === cartItem.product.id), 1);
        this.cartItems$.next(this.cartItems);
        this.cartItemAmountChanged$.next(cartItem.reward_group_index);
      }
      this.calculateTotal();
      if(this.user) {
       const sub = this.cartService.getStockConditions().subscribe((cartConditions: CartCondition[]) => {
          this.stockConditionsUpdate$.next(cartConditions);
          this.checkStockConditions(cartConditions);
          sub.unsubscribe();
        }, err => sub.unsubscribe());
      }
     })
     );
  }

  getTotalWeight(): number {
    let weight = 0;
    this.selectedCartItems.forEach((cartItem: CartItem) => {
      weight += cartItem.amount * cartItem.product.weight_brutto;
    });
    this.cartRewards.forEach((cartReward: CartReward) => {
      weight += cartReward.amount * cartReward.product.weight_brutto;
    });
    return parseFloat((weight / 1000).toFixed(2));
  }

  getSelectedProductIds(): number[] {
   return this.selectedCartItems.map( (cartItem: CartItem) => cartItem.product.id );
  }
  getSelectedProductSlugs(): string[] {
    return this.selectedCartItems.map( (cartItem: CartItem) => cartItem.product.slug );
   }

  setDeliveryPrice(price: number = 0) {
    this.deliveryPrice = price;
  }

  // Региональная наценка
  setMarkupPercent(percent: number = 0) {
    this.markupPercent = percent;
  }

  reset() {
    this.cartItems = [];
    this.selectedCartItems = [];
    this.discount = 0;
    this.totalPrice = 0;
    this.totalCustomerPrice = 0;
    this.selectedTotalAmount = 0;
    this.totalAmount = 0;
    this.deliveryPrice = 0;
    this.totalPriceWithDelivery = 0;
    this.totalCustomerPriceWithDelivery = 0;
    this.points = 0;
    this.cartItems$.next([]);
  }
  getCart(): Observable<CartItem[]> {
    const cityId = this.cityService.getCityId();
    if (cityId) {
     const query = new CartUpdateCityQuery();
     query.new_city_id = cityId;
     return this.cartService.updateCartCity(query).pipe(
            catchError((error) =>  of(error)),
            mergeMap(() => this.cartService.getCart()),
            tap((data: CartItem[] ) => this.handleRespone(data))
            );
    } else {
      return this.cartService.getCart().pipe(tap((data: CartItem[]) => this.handleRespone(data)));
    }
  }

  private handleRespone(data: CartItem[]) {
    this.cartItems$.next(data);
    this.cartItems = data;
    if (data && data.length > 0) {
      this.currencyService.setCurrency(data[0].product.currency);
    }
    this.calculateCartTotalAmount();
    this.selectedCartItems = this.cartItems.filter(item => item.is_selected);
    this.getCartRewards();
    this.cartItemsUpdate$.next(true);
  }

  subscribeToCityChange() {
    this.cityService.cityChange$.subscribe((city) => {
      if (city) {
        if (this.cartItems.length > 0) {
          const query = new CartUpdateCityQuery();
          query.new_city_id = city.id;
          const sub = this.cartService.updateCartCity(query).subscribe(() => sub.unsubscribe());
        }
      }
    });

  }

  // checks if a cart should fully update
  checkStockConditions(cartConditions: CartCondition[]) {
    // if we have any cart's stocks conditions
    if (cartConditions && cartConditions.length > 0) {
      // if a value required by condition is true (for example we have enought points to display cart gifts)
      if (cartConditions[0].params.current_bpv >= cartConditions[0].params.required_bpv) {
        // if there are no gifts in cart
        if (!this.isCartHasRewards()) {
          // fully update a cart in order to get gifts
          this.runCartUpdate$.next(true);
        }
      } else { // if a value required by condition is false (for example we don't have enought points to display cart gifts)
        if (this.isCartHasRewards()) { // if there are gifts in cart
          this.runCartUpdate$.next(true); // fully updates a cart to get rid of gifts
        }
      }
    } else {
      if (!this.isCartHasRewards()) {
        // fully update a cart in order to get gifts
        this.runCartUpdate$.next(true);
      }
    }
  }

  private isCartHasRewards() {
    for (const cartItem of this.cartItems) {
      if (cartItem.reward_products && cartItem.reward_products.length > 0) {
        return true;
      }
    }
  }

  getStockConditions() {
   if(this.user) {
     this.stockService.activeStock$.pipe(filter(stock => !!stock)).subscribe(() => {
      this.cartService.getStockConditions().subscribe((cartConditions: CartCondition[]) => {
        this.stockConditionsUpdate$.next(cartConditions);
       });
     })
   }
  }

  getCartRewards() {
    this.userService.user$.pipe(mergeMap((user: UserModel) => {
     return  user? this.cartService.getCartRewards(): of(null);
    })).subscribe((cartRewards: CartReward[]) => {
      if(cartRewards) {
        this.cartRewars$.next(cartRewards);
        this.cartItems.map(cartItem => cartItem.reward_products = null);
        this.cartService.setCartRewards(this.cartItems, cartRewards);
      }
      this.sub.unsubscribe();
    }, () => {
      this.sub.unsubscribe();
    });
  }

  isCartItemUnavailable(cartItem: CartItem): boolean {
   return !cartItem.is_delivery_available || cartItem.product.status.id === ProductStatuses.deactivated ||
        cartItem.product.status.id === ProductStatuses.hidden;
  }

  isCartItemAvailable(cartItem: CartItem): boolean {
    return cartItem.is_delivery_available && cartItem.product.status.id === ProductStatuses.published;
  }
}
