<app-cookies></app-cookies>

<footer class="footer">
  <div class="container">
    <div class="footer__col">
      <div class="footer__col--title" i18n>Online shop</div>
      <div class="footer__menu">
        <a routerLink="/catalog" class="footer__menu--link" i18n='@@common.catalogue'>Catalogue</a>
        <a routerLink="/delivery" class="footer__menu--link" target="_blank" i18n>Delivery</a>
        <!--
        <a href="#" class="footer__menu--link">Оплата</a>
        -->
      </div>
    </div>

    <div class="footer__col md">
      <div class="footer__col--title">Vertera</div>
      <div class="footer__menu">
        <!-- <a routerLink="/about" class="footer__menu--link" i18n>About company</a> -->
        <!--
        <a href="https://old.dev.vertera.org/geography" target="_blank" class="footer__menu--link">География</a>
        <a href="#" class="footer__menu--link">Партнерская программа</a>
        -->
        <a routerLink="/blog" class="footer__menu--link" i18n="@@common.blog">Blog</a>
        <a routerLink="/about" class="footer__menu--link" i18n="@@common.about_us">About us</a>
        <a *ngIf="!user" class="footer__menu--link" (click)='goToPartnerCabinet()' i18n>Partner's office</a>
      </div>
      <div class="footer__menu">
        <a routerLink="/faq" target="_blank" class="footer__menu--link" i18n="@@common.faq-menu">Questions and answers</a>
        <a *ngIf='false' routerLink="/callback" class="footer__menu--link" i18n>Call order</a>

        <a *ngIf="!user" (click)='login()' class="footer__menu--link" i18n="@@sign_in">
          Sing in
        </a>
        <a *ngIf="!user" (click)='register()' class="footer__menu--link" i18n="@@sing_up">
          Sign up
        </a>
        <a *ngIf="user" class="footer__menu--link" target="_blank" (click)='goToPartnerCabinet()' i18n>Partner's office</a>
      </div>
    </div>

    <div class="footer__col lg-max">
      <div class="footer__col--title" >Vertera</div>

      <div class="footer__menu">
        <!-- <a routerLink="/about" class="footer__menu--link" i18n>About company</a> -->
        <!--
        <a href="https://old.dev.vertera.org/geography" target="_blank" class="footer__menu--link">География</a>
        <a href="#" class="footer__menu--link">Партнерская программа</a>
        -->
        <a routerLink="/blog" class="footer__menu--link" i18n="@@common.blog">Blog</a>
        <a routerLink="/about" class="footer__menu--link" i18n="@@common.about_us">About us</a>
        <a *ngIf="!user" class="footer__menu--link" target="_blank" (click)='goToPartnerCabinet()' i18n>Partner's office</a>
      </div>
    </div>

    <div class="footer__col lg-max">
      <div class="footer__menu custom-block">
        <a routerLink="/faq" target="_blank" class="footer__menu--link" i18n="@@common.faq-menu">Questions and answers</a>
        <a *ngIf='false' routerLink="/callback" class="footer__menu--link" i18n>Call order</a>
        <a *ngIf="!user" (click)='login()' class="footer__menu--link" i18n="@@sign_in">
          Sing in
        </a>
        <a *ngIf="!user" (click)='register()' class="footer__menu--link" i18n="@@sing_up">
          Sign up
        </a>
        <!--
        <a *ngIf="user" (click)='goToUserCabinet()' class="footer__menu--link" i18n>
          Personal cabinet
        </a>
        -->
        <a *ngIf="user" class="footer__menu--link" (click)='goToPartnerCabinet()' i18n>Partner's office</a>
      </div>
    </div>

    <div class="footer__col">
      <div class="footer__social">
        <div class="footer__col--title" i18n>WE IN SOCIAL NETWORKS</div>
        <a *ngIf="lang !== 'ru'" href="https://www.facebook.com/verteraorganic" class="footer__social--link" target="_blank" >
          <svg-icon name="social--fb" class="fb"></svg-icon>
        </a>
        <a href="https://vk.com/verteraorganic" class="footer__social--link" target="_blank">
          <svg-icon name="social--vk" class="vk"></svg-icon>
        </a>
        <a *ngIf="lang !== 'ru'" href="https://www.instagram.com/vertera_company/" class="footer__social--link" target="_blank">
          <svg-icon name="social--inst" class="inst"></svg-icon>
        </a>
        <a href="https://www.youtube.com/user/verteraorganic" class="footer__social--link" target="_blank">
          <svg-icon name="social--youtube" class="youtube"></svg-icon>
        </a>
        <a href="https://ok.ru/verteraorganic" class="footer__social--link" target="_blank">
          <svg-icon name="social--ok" class="ok"></svg-icon>
        </a>
      </div>
      <div class="footer__contact">
        <a href="tel:+79301600046" class="footer__contact--phone">+7 (930) 160-00-46</a>
        <p class="footer__contact--text">ВЕРТЕРА МЦ ДООЕЛ Скопје,<br>
          Ул. БОРИС ТРАЈКОВСКИ Бр.130 СКОПЈЕ - КИСЕЛА ВОДА<br>
          Рег. номер 35020200005298<br>
          email: <a href="mailto:estonia@vertera.org">estonia@vertera.org</a><br>
      </div>
    </div>
  </div>
  <div class="footer__bottom">
    <div class="container">
      <div class="footer--half">
        <span class="footer--copy">&copy;</span>
        <span i18n>2013 - {{copyright}} Vertera. Enjoy life with Vetera natural products!</span>
      </div>
      <div class="footer--half">
        <a routerLink="/returns" target="_blank" class="footer--link" i18n='@@footer.returns_refunds_policy'>Retuns & Refunds Policy</a>
        <a routerLink="/policy" target="_blank" class="footer--link" i18n>Privacy policy</a>
        <a routerLink="/terms" target="_blank" class="footer--link" i18n>Terms of use</a>
      </div>
    </div>
  </div>
  <div class="footer__scroll" [ngClass]="{ 'scroller': scroller }" (click)="scrollToTop()">
    <img appStatic="/assets/icons/ic/scroll-top.svg" />
  </div>
</footer>
