import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { CityQuery, GqlCityQuery } from '@core/models/hdbk/city.model';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CityQueryModel, CityModel } from '@core/models/geo/city.model';
import { PageResult } from '@core/models/page/page.model';
import { AppStorage } from '@core/storage';
import { environment } from 'src/environments/environment';
import { CountryModel } from '@core/models/hdbk/country.model';
import { CITIES } from './cities';

@Injectable()
export class CityService {

  readonly cityKey: string = `${environment.appName}_city`;
  readonly cityChange$ = new ReplaySubject<CityModel | void>(1);
  allowFirstCityChange = true;

  public readonly CITY: {[key: string]: CityModel} = {
    defaultCity: {
      id: 5489100,
      name: 'Skopje',
      country: {
        id: 273,
        name: 'Македонија',
        cities: [],
        alpha2: 'MK'
      },
    },
  };

  constructor(
    private http: HttpClient,
    @Inject(AppStorage) private appStorage: Storage,
    @Inject(BASE_URL) private baseUrl: string
  ) {
    this.CITY = Object.assign(this.CITY, CITIES)
  }

  getPage(query: CityQueryModel): Observable<PageResult<CityModel[]>> {
    const gqlQuery = new GqlCityQuery();
    return this.http.get<any>(`${this.baseUrl}/hdbk/geo/city?${gqlQuery.toRest()}&${query.getQuery()}`
      , { observe: 'response' })
      .pipe(
        map(resp => new PageResult<CityModel[]>(resp))
      );
  }

  getCities(query: CityQuery): Observable<CityModel[]> {
    const gqlQuery = new GqlCityQuery(query);
    return this.http
      .get<{ data: CityModel[] }>(
        `${this.baseUrl}/hdbk/geo/city?${gqlQuery.toRest()}`
      )
      .pipe(
        map(res => {
          return res.data;
        }),
        catchError(err => throwError(err))
      );

  }

  setCity(city: CityModel) {
    const prevCityId = this.getCityId();
    if (city && city.country) {
      if (!city.country.cities) {
        city.country.cities = [];
      }
      this.appStorage.setItem(this.cityKey, JSON.stringify(city));
      if(city.id !== prevCityId || this.allowFirstCityChange) {
        this.cityChange$.next(city);
        this.allowFirstCityChange = false;
      }
    }
  }

  getCity(): CityModel {
    const city = this.appStorage.getItem(this.cityKey);
    return city ? JSON.parse(city) : null;
  }

  getCountry(): CountryModel {
    const city = this.getCity();
    if(city && city.country) {
      return city.country;
    }
  }

  removeCity() {
    this.appStorage.removeItem(this.cityKey);
  }

  getCityId(): number {
    const city = this.getCity();
    return city ? city.id : null;
  }
  getCountryId(): number {
    const city = this.getCity();
    if (city && city.country) {
      return city.country.id;
    } else {
      return 113; // Russia
    }
  }
  getCountryCode(): string {
    const city = this.getCity();
    if (city && city.country && city.country.alpha2) {
      return city.country.alpha2;
    } else {
      return 'RU'; // Russia
    }
  }
}
