import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Geo } from '@vertera-common/abstract/geo';
import { FormBuilder } from '@angular/forms';
import { CityService } from '@core/services/hdbk/city.service';
import { CountryService } from '@core/services/hdbk/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoService } from '@core/services/geo.service';
import { LanguageService } from '@core/services/language.service';
import { DeliveryViewService } from 'src/app/cart/services/delivery-view.service';
import { MatDialog } from '@angular/material';
import { CountryRedirectService } from '@core/services/common/country-redirect.service';

@Component({
  selector: 'app-delivery-city',
  templateUrl: './delivery-city.component.html',
  styleUrls: ['./delivery-city.component.scss']
})
export class DeliveryCityComponent extends Geo implements OnInit {

  constructor(
    protected formBuilder: FormBuilder,
    protected cityService: CityService,
    protected countryService: CountryService,
    protected changeDetector: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected router: Router,
    protected geoService: GeoService,
    protected languageService: LanguageService,
    protected deliveryViewService: DeliveryViewService,
    protected dialog: MatDialog,
    protected countryRedirectService: CountryRedirectService,
  ) {
    super(formBuilder, cityService, countryService, changeDetector, route, router, geoService, languageService,
      dialog, countryRedirectService);
  }

  submitted: boolean;


  ngOnInit() {
    const city = this.cityService.getCity();
    if (city && city.country && city.country.id) {
      this.skipGeoDetection = true;
    }
    super.ngOnInit();
    this.subsribeToCitySelection();
    this.subscribeToCityChange();
    this.subscribeToFormSubmit();
  }

  modifyQuery(query) {
    query.has_storage = 1;
  }

  subsribeToCitySelection() {
    const sub = this.onCitySelected$.subscribe(() => {
      this.cityService.setCity(this.city.selected);
      this.changeDetector.detectChanges();
    });
    this.sub$.add(sub);
  }

  subscribeToFormSubmit() {
    const sub = this.deliveryViewService.submit$.subscribe(() => {
      this.submitted = true;
      this.changeDetector.detectChanges();
    });
    this.sub$.add(sub);
  }

}
