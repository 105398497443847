import { User } from '../user/user.model';
import { Product, gqlProductMinFields } from '../product/product.model';
import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';
import { Stock, gqlStockFields } from '../user/stock.model';
import { PageQuery } from 'src/app/catalog/abstract/catalog.model';
import { PageResult } from '../page/page.model';
import { HttpResponse } from '@angular/common/http';
import { CityModel } from '../geo/city.model';

export enum Application_id {
  Angiolive = 1,
  VerteraGel = 2
}

export enum ORDER_STATUSES {
  new = 1,
  performed = 3,
  canceled = 98,
  completed = 99,
  sent = 200 // TODO поменять на реальный id как появится статус для заказа Отправлен
}

export interface OrderStatus {
  type_id: number;
  name: string;
}

interface DeliveryType {
  type_id: number;
}

interface OrderItem {
  id: number;
  order_id: number;
  amount: number;
  price: number;
  product: Product;
}

export interface OrderRewardItem {
  amount: number;
  product: Product;
}

export interface Storage {
  id: number;
  name: string;
  address: string;
  city: CityModel;
}

interface Application {
  id: number;
  title: string;
}

export interface Order {
  id: number;
  id_os: number;
  storage_id: number;
  user_id: number;
  application_id?: number;
  status: OrderStatus;
  delivery_type: DeliveryType;
  delivery_price: number;
  currency_code: string;
  price_total: number;
  user: User;
  order_items: OrderItem[];
  storage: Storage;
  application: Application;
  user_stock: Stock;
  created_at: number;
  price_delivery: number;
  total_to_pay: number;
  rewards_count: number;
  order_rewards: OrderRewardItem[];
  track_number: string;
}

export class PageFilterResult<T> extends PageResult<T> {
  years: string;
  constructor(response: HttpResponse<{data: T}>) {
    super(response);
    this.years = response.headers.get('X-Filter-Range-Year');
  }
}

export class OrderQuery {
  storage_id: number;
  currency_code: string;
  delivery_type_const: number;
  delivery_type_calculation_const: number;
  delivery_user_address_id: number;
  email: string;
  phone: string;
  fname: string;
  lname: string;
  ref_code?: string;
  agree: boolean;
  stat_uuid: string;
  is_background_registration: number;
  delivery_country_id: number;
  delivery_city_id: number;
  delivery_point_id?: string;
}

export class OrderInfo extends OrderQuery {
  id: number;
  id_os: number;
  address: string;
  totalPrice: number;
  discount: number;
  totalPriceWithDelivery: number;
}

export class OrderFilterQuery extends PageQuery  {
  created_at_left: any;
  created_at_right: any;
}

export class OrderEditQuery {
  status_const: number;
}

export const gqlOrderItem = `{
  id
  order_id
  amount
  price
  product {
    ${gqlProductMinFields}
  }
}`;

export const gqlOrderFields = `{
  id
  id_os
  order_items ${gqlOrderItem}
  user_stock ${gqlStockFields}
  order_rewards  {
    amount
    product {
      id
      title
      weight_brutto
      main_image {
        id
        uri
        thumbs
      }
    }
  }
  status
  price_total
  price_delivery
  total_to_pay
  markup_price
  rewards_count
  created_at
  currency_code
  storage {
    city {
      id
      name
      country {
        id
        name,
        alpha2
      }
    }
  }
  track_number
}`;

export class GqlOrderQuery extends GqlQuery {
  fields = gqlOrderFields;
}
