import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';
import { CityModel } from '@core/models/geo/city.model';
import { Subject } from 'rxjs';

export enum COUNTRY_CODES {
  Turkey = 'TR',
  Azerbaijan = 'AZ',
  Russia = 'RU',
  Belarus = 'BY',
  Kazakhstan = 'KZ',
  Kyrgyzstan = 'KG',
  Uzbekistan = 'UZ',
  Macedonia = 'MK',
  Serbia = 'RS'
}

export class GeoEntity {
  isLoading: boolean;
  entities = [];
  entitiesForDD = [];
  public readonly allowSearch$ = new Subject<boolean>();
  public readonly search$ =  new Subject<string>();
  public query: any;
  public selected: CountryModel | CityModel;

  constructor(q) {
    this.query = q;
  }
}

export class Region {
  id: number;
  name: string;
  count?: number;
  cities: CityModel[];

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.cities = [];
  }
}

export class CountryModel {
  id: number;
  name: string;
  cities: CityModel[] = [];
  alpha2: string;
  os_country_id?;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class CountryQueryModel {
  search: string;
}

export const gqlCountryFields = `{
  id
  name
  alpha2
  os_country_id
}`;

export class GqlCountryQuery extends GqlQuery {
  fields = gqlCountryFields;
}
