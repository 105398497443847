import { Component, OnDestroy, AfterViewInit, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { QueryLogService } from '@core/services/common/query-log.service';
import { CityService } from '@core/services/hdbk/city.service';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ScrollToTopService } from '@vertera-common/services/scroll-to-top.service';
import { RefService } from '@core/services/user/ref.service';
import { MetaService } from '@ngx-meta/core';
import { LanguageService } from '@core/services/language.service';
import { NgAnimateScrollService, NotificationService } from '@core/services';
import { AppStorage } from '@core/storage';
import { MANGO_LANG_TO_ID } from '@core/lib/const/scripts';
import { VrMetaService } from '@core/services/hdbk/vr-meta.service';
import { META_MESSAGES } from '@core/lib/const/messages';
import { filter, take } from 'rxjs/operators';
import { AuthMethodService } from '@core/services/auth/auth-method.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  providers: [ ScrollToTopService ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  sub$: Subscription = new Subscription();
  title = 'vertera';
  cityChosen: boolean;

  constructor(public angulartics: Angulartics2GoogleGlobalSiteTag,
              public router: Router,
              public route: ActivatedRoute,
              public queryLogService: QueryLogService,
              private cityService: CityService,
              @Inject(PLATFORM_ID) private platformId: any,
              private scrollToTopService: ScrollToTopService,
              private scrollService: NgAnimateScrollService,
              private refService: RefService,
              private readonly meta: MetaService,
              private languageService: LanguageService,
              private notify: NotificationService,
              @Inject(AppStorage) private appStorage: Storage,
              @Inject(DOCUMENT) protected document: any,
              private vrMetaService: VrMetaService,
              private authMethodService: AuthMethodService,
            ) {
    angulartics.startTracking();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const sub = this.queryLogService.sendQueryLog(this.route.snapshot.queryParams).subscribe();
      this.sub$.add(sub);
      this.redirectToGeoLang();
      if (this.appStorage.getItem('is_register')) {
        this.scrollService.openOnTop();
        this.notify.openNotification({type: 'top', data: {}});
        this.appStorage.removeItem('is_register');
      }
    }, 1000);
  }

  ngOnInit() {
    this.setMangoId();
    this.setMetaTags();
    this.subscribeToRouteChange();
    this.checkAuthType();
    if (isPlatformBrowser(this.platformId)) {
      this.scrollToTopService.init();
      this.cityChosen = !!this.cityService.getCity();
    }
  }

  ngOnDestroy() {
    this.scrollToTopService.destroy();
    this.sub$.unsubscribe();
  }

  setMetaTags() {
    this.vrMetaService.getCurrentPageMeta().pipe(take(1)).subscribe((currentPageMeta) => {
      if(!currentPageMeta) {
        this.setDefaultMetaTags();
      }
    })
  }

  subscribeToRouteChange() {
    const sub = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.vrMetaService.getCurrentPageMeta().pipe(take(1)).subscribe();
      });
    this.sub$.add(sub);
  }

  setDefaultMetaTags() {
    const language = this.languageService.getLanguage();
    this.meta.setTitle(META_MESSAGES.main_page_title[language]);
    this.meta.setTag('description', META_MESSAGES.main_page_description[language]);
  }

  private setMangoId(): void {
    if (isPlatformServer(this.platformId)) {
      const mangoId = MANGO_LANG_TO_ID[this.languageService.getLanguage()];
      // set pixel id to head in server side
      const scripts: any = this.document.querySelectorAll('head script');
      if (scripts && scripts[0]) {
        scripts[0].text = scripts[0].text.replace('<mango_id>', mangoId);
      }
    }
  }

  private redirectToGeoLang(): void {
    if (isPlatformBrowser(this.platformId) && (!this.cityChosen || this.route.snapshot.queryParams.global === 'true')) {
      const global_country_id = this.route.snapshot.queryParams.global_country_id;
      if (global_country_id) {
        this.router.navigate(['geo-lang'],
         {queryParamsHandling: 'merge', queryParams: {country_id: global_country_id, global_country_id: undefined}});
      } else {
        this.router.navigate(['geo-lang'], {queryParamsHandling: 'merge'});
      }
    }
  }

  checkAuthType(): void {
    this.authMethodService.fetch().pipe(take(1)).subscribe();
  }
}
