<div class="dropdown" [class._opened]="openGeoMenu" *ngIf='showWidget'>
  <app-transparent-button class="menu__geo--button dropdown-toggle" pt="pt0" (click)="toggleGeoMenu()">
    <svg-icon name="geo--marker" [size]="17" class="menu__geo--icon"></svg-icon>
    <span class="menu__geo--text" i18n>Your city:</span>
    <span class="menu__geo--city">{{ city?.selected?.name }}</span>
  </app-transparent-button>

  <form class="dropdown-menu" [formGroup]="form">
    <svg-icon class="dropdown-menu--close" name="control--close" size="12" (click)="toggleGeoMenu()"></svg-icon>
    <p i18n>Select the region so that we more accurately calculate the delivery conditions</p>
    <ng-select i18n-placeholder placeholder="Country" id="country_id"
        [items]="country.entitiesForDD"
        [loading]="country.isLoading"
        bindLabel="name" bindValue="id"
        loadingText='Loading...' i18n-loadingText="@@ng_select.loading"
        notFoundText='Country is not found' i18n-notFoundText="@@ng_select.country_not_found"
        (search)="searchCountry($event)"
        (change)="onCountrySelected($event)"
        formControlName="country_id">
    </ng-select>
    <ng-select i18n-placeholder placeholder="City" id="city_id" class="form__select hidden-arrow"
          [ngClass]="{error: submitted && !city.selected}"
          [items]="city.entitiesForDD"
          [loading]="city.isLoading"
          typeToSearchText="Type to search" i18n-typeToSearchText="@@ng_select.type_to_search"
          loadingText='Loading...' i18n-loadingText="@@ng_select.loading"
          notFoundText='City is not found' i18n-notFoundText="@@ng_select.city_not_found"
          bindLabel="name" bindValue="id"
          (search)="searchCity($event)"
          [typeahead]="city.search$"
          formControlName="city_id"
          (change)="onCitySelected($event)">
      </ng-select>
    <div class="cities">
      <span (click)='selectCityFromList(city)' class="dashed city"*ngFor="let city of defaultCities; let isLast = last">
        {{ city?.name }}{{isLast ? '' : ','}}</span>
    </div>
    <app-white-button class="geo-select__identify" >
      <svg-icon name="geo--marker" [size]="16"></svg-icon>
      <span class="check" i18n (click)='detectByLocation()'>Locate by My Location</span>
    </app-white-button>
  </form>
</div>

