import {AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryRedirectService } from '@core/services/common/country-redirect.service';
import { GeoService } from '@core/services/geo.service';
import { CityService } from '@core/services/hdbk/city.service';
import { CountryService } from '@core/services/hdbk/country.service';
import { LanguageService } from '@core/services/language.service';
import { DeliveryCityComponent } from 'src/app/cart/components/delivery/components/delivery-city/delivery-city.component';
import { DeliveryViewService } from 'src/app/cart/services/delivery-view.service';


@Component({
  selector: 'app-modal-geo',
  templateUrl: './modal-geo.component.html',
  styleUrls: ['./modal-geo.component.scss']
})
export class ModalGeoComponent extends DeliveryCityComponent implements AfterViewInit   {
  selectedCity: string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    protected formBuilder: FormBuilder,
    protected cityService: CityService,
    protected countryService: CountryService,
    protected changeDetector: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected router: Router,
    protected geoService: GeoService,
    protected languageService: LanguageService,
    protected deliveryViewService: DeliveryViewService,
    protected dialog: MatDialog,
    protected countryRedirectService: CountryRedirectService,
    protected cd: ChangeDetectorRef,
  ) {
    super(formBuilder, cityService, countryService,
      changeDetector, route, router, geoService, languageService, deliveryViewService, dialog, countryRedirectService);
  }

  ngAfterViewInit() {
    this.selectedCity = this.city.selected.name;
    this.cd.detectChanges();
  }

  onCitySelected(city) {
    this.formControls.city_id.setValue(city.id);
    this.city.selected = city;
  }

  save() {
    this.selectedCity = this.city.selected.name;
    this.onCitySelected$.emit(this.city.selected);
    setTimeout(() => {
      this.clickClose();
    }, 100)
  }

  clickClose(): void {
    this.dialogRef.close();
  }
}
